@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.FormAnt {
  &:global(.ant-form-vertical .FormItem) {
    width: 100%;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  &:global(.ant-form-inline) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .ant-form-item {
      margin-right: 15px;
      > div {
        display: block;
        text-align: left;
      }
      .ant-form-item-label {
        label {
          font-size: 11px;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  :global(.ant-form-item-label) {
    padding: 0 0 5px;
    line-height: 1.5;
  }
}
