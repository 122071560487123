@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
.component {
  color: colors.$neutral700;
}
// 48/48; semibold / light (prop light)
.xl {
  font-size: 48px;
  line-height: 1;
  font-weight: 600;
  &.light {
    font-weight: 200;
  }
}
// 32/40; light
.lg {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 200;
}
// 24/28; light
.md {
  font-size: 24px;
  line-height: 1.16;
  font-weight: 200;
}
// 17/22; bold
.sm {
  font-size: 17px;
  line-height: 1.29;
  font-weight: 600;
}
// 13/16; bold
.xs {
  font-size: 13px;
  line-height: 1.23;
  font-weight: 600;
}
