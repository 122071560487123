@use "src/styles/theme" as *; $themeName: "fixtercore";
.collapseContainer {
  border: 1px solid #E5E3F0;
  border-radius: 8px;
  width: 100%;
  &.isOpen {
    &.isElevated {
      border: 1px solid #FFFFFF00;
      box-shadow: 0 16px 32px 0 rgba(50,56,62,0.16);
    }
  }

  .collapseContent {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
    padding: 0 16px;
  }

  &.isOpen {
    .collapseContent {
      max-height: fit-content;
      opacity: 1;
      padding: 0 16px 16px 16px;
      transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
    }
  }
}

.collapseHeader {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.icon {
  transition: transform 0.25s ease-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.rotate {
    transform: rotate(180deg);
  }
}

.collapseContent {
  padding: 16px;
}
