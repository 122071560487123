@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/colors";
@use "$styles/variables/elevation";
@use "$styles/tools/mixins/typography";
@use '$styles/variables/spacings';

.component {
	height: 56px;
    
    @include mediaqueries.smallUp {
        // space for .sticky
        height: 80px;
    }
    :global {
        @include mediaqueries.upToLarge {
            .NavigationMenu {
                .-guest {
                    display: block;
                    margin: auto 0 125px 0;
                    a {
                        @include typography.labelLTypographyStyles;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.sticky {
    @include mediaqueries.smallUp {
        position: fixed;
        top: auto;
        z-index: elevation.$top;
        width: 100%;
    }
}

.delimiter-right {
    margin-left: auto;
}

.need-help-cta {
    @include mediaqueries.smallUp {
        color: colors.$white;

        div div::before {
            background-color: colors.$white !important;
        }

        span {
            color: colors.$purple500;
        }
    }

    @include mediaqueries.largeUp {
        margin-right: 32px;
        > div {
            margin-right: 5px;
        }
    }
}


.header-right {
    display: flex;
    align-items: center;
    .current-user_desktop {
        display: none;
        @include mediaqueries.largeUp {
            display: block;
        }
        a {
            color: colors.$white;
            font-weight: 600;
            font-size: 15px;
        }
    }
}

.current-user_mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 78px;
    width: 100%;
    border-top: 1px solid colors.$neutral200;
    display: flex !important;
    align-items: center;
    justify-content: center;

    @include mediaqueries.largeUp {
        display: none !important;
    }
    
    a {
        @include typography.labelMTypographyStyles;
        color: colors.$neutral800;
    
        &:hover, &:active, &:focus {
            color: colors.$purple500;
            text-decoration: underline;
        }
    }
}


.mobileDropdown {
    > span {
        bottom: spacings.$XXL + spacings.$XXS;
        left: -120px;
        width: 300px;
    }
}

.loginIconContainer {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: colors.$brandPurple;
    color: colors.$white;
    margin-left: spacings.$XXS;
}

.icon {
    color: colors.$white;
}
