@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";

.component {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: colors.$purple500;
  }
}
