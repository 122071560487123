@use "src/styles/theme" as *; $themeName: "fixtercore";
.container {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: 1440px;
  &.-large {
    max-width: 1920px;
  }
}
