@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';

.component {
  font-size: 14px;
  text-decoration: underline solid transparent;
  font-weight: 400;
  color: colors.$neutral600;
  transition:
    text-decoration 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    font-weight 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:visited {
    color: colors.$neutral600;
    text-decoration: underline solid;
  }
  &:hover {
    color: inherit;
    text-decoration: underline solid currentColor;
  }
}
.link {
  &-1 {
    color: colors.$neutral300;
    text-decoration-color: currentColor;
    &:hover {
      color: colors.$purple500;
    }
  }
  &-2 {
    color: colors.$purple500;
    text-decoration-color: currentColor;
    &:hover {
      text-decoration-color: transparent;
    }
  }
  /* 3 is the default */
  &-4 {
    text-decoration-color: currentColor;
    &:hover {
      color: colors.$purple500;
    }
  }
  &-5 {
    color: colors.$neutral700;
    font-weight: 600;
  }
}
