@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.FormItem {
  display: block;
  :global(&.-focussed .ant-input-affix-wrapper .anticon svg) {
    fill: colors.$purple500;
  }
  :global(.ant-form-item-label label) {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
  :global(.ant-form-item-label .anticon) {
    margin-left: 5px;
    vertical-align: middle;
  }
  :global(.ant-input-affix-wrapper .anticon svg) {
    transition: all 0.5s ease-out;
  }
  :global(.ant-form-explain) {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 1.7;
    color: colors.$red300;
  }
  :global(.has_error) {
    .ant-input,
    .ant-input:hover {
      border-color: colors.$neutral200;
    }
    .ant-input:focus {
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
  :global(.ant-form-item-control) {
    width: 100%;
  }
}
