@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: colors.$purple500;
  }
}
